<template>
	<div id="register-client">
		<div class="screen-title">クライアント編集</div>
		<b-container fluid>
			<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onEdit)">
					<b-row class="mx-0 d-flex row-gap-20">
						<card class="my-0 w-100">
							<div class="card-title">基本情報</div>
							<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="会社/屋号"
										name="会社/屋号"
										v-model="model.clientName"
										label="会社/屋号"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="メールアドレス"
										name="メールアドレス"
										v-model="model.mailAddress"
										label="メールアドレス"
										:rules="{
											required: true,
											validate: [
												[clientId, model.mailAddress],
												'このメールアドレスが存在しました。別のメールアドレスをご記入ください',
												checkEmailValidate,
											],
											email: true,
										}"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom d-flex col-gap-20">
									<!-- <base-input
										alternative
										placeholder="郵便番号"
										name="郵便番号"
										:value="model.postcode"
										label="郵便番号"
										type="text"
										@keydown="onKeyDownPostalCode"
										@input="onPostalCodeInput"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
									>
									</base-input> -->

									<!-- An added Area -->
									<base-input
										alternative
										placeholder="郵便番号"
										name="郵便番号"
										v-model="model.postcodeAddressJapan"
										@blur="onBlurPostcode"
										label="郵便番号"
										type="text"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
										:noteError="errorNoteMsg"
									>
									</base-input>
									<!-- An added Area -->

									<base-input
										label="都道府県"
										:labelClasses="'custom-form-input-label'"
									>
										<el-select
											placeholder="選択なし"
											v-model="provinceSelect"
											filterable
											clearable
										>
											<el-option
												v-for="option in provinceOptions"
												:key="option.value"
												:label="option.label"
												:value="option"
											>
											</el-option>
										</el-select>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="住所"
										name="住所"
										v-model="model.address"
										label="住所"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="担当者名"
										name="担当者名"
										v-model="model.managerName"
										label="担当者名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										unit="様"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="担当者名フリガナ"
										name="担当者名フリガナ"
										v-model="model.managerNameInFurigana"
										label="担当者名フリガナ"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										unit="様"
									>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="電話番号"
										name="電話番号"
										v-model="model.phoneNumber"
										label="電話番号"
										type="text"
										@keydown="onKeyDown"
										@blur="checkPhoneNumber"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="FAX番号"
										name="FAX番号"
										v-model="model.faxNumber"
										label="FAX番号"
										type="text"
										@keydown="onKeyDown"
										@blur="checkFaxNumber"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
									>
									</base-input>
								</div>
							</b-row>
						</card>
						<card class="w-100 my-0">
							<div class="card-title">LINEアカウントの紐付けURL</div>
							<b-row class="custom__mx-3">
								<base-input
									alternative
									class="mb-3"
									placeholder="LINEアカウントの紐付けURL"
									name="LINEアカウントの紐付けURL"
									v-model="model.lineVerifyUrl"
									:prepend-icon="copyBtn"
									:textContent="textContent"
									label=""
									:disabled="true"
									@on-icon-click="onCopy(model.lineVerifyUrl)"
									:inputClasses="'custom-input-class'"
								>
								</base-input
							></b-row>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">備考</div>
							<textarea
								class="form-control"
								type="text"
								v-model="model.clientRemark"
								style="resize: none"
								rows="5"
							></textarea>
						</card>
					</b-row>
					<b-row class="mx-0 px-0 my-4 w-100 d-flex justify-content-center col-gap-20">
						<button native-type="submit" class="customButton customButton__submit">
							変更
						</button>
						<button @click.prevent="onDelete" class="customButton customButton__delete">
							削除
						</button>
					</b-row>
				</b-form>
			</validation-observer>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			:redirect="redirectLink"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import ClientService from '@/services/client.service';
import UserService from '@/services/user.service';
import zipAddressService from '@/services/zip_address.service';

import { Select, Option, DatePicker } from 'element-ui';
import moment from 'moment';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';

import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';
import CommonConst from '@/util/const';

export default {
	name: 'RegisterClient',
	mixins: [clientPaginationMixin, autoAddressMixin, postcodeMixin],
	components: {
		[DatePicker.name]: DatePicker,
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
	},
	data() {
		return {
			notiModalId: 'user-edit-noti',
			notiContent: '',
			notiSingleBtn: false,
			redirectLink: '',
			notiMode: '',
			model: {
				clientName: '',
				postcode: undefined,
				address: undefined,
				clientRemark: undefined,
				managerName: undefined,
				managerNameInFurigana: undefined,
				phoneNumber: undefined,
				faxNumber: undefined,
				mailAddress: '',
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			provinceSelect: '',
			provinceOptions: [],
			loginUserId: '',
			clientId: '',
			editSuccessMessage: '変更が完了しました',
			alertMessage: '',
			alertType: '',
			showAlert: false,
			copyBtn: 'copy-btn',
			textContent: 'URLをコピー',

			isClientHaveActiveProject: false,
		};
	},
	watch: {
		async 'model.postcode'(newPostcode, oldPostcode) {
			if (!newPostcode) return;

			if (newPostcode.length === CommonConst.POST_CODE_LENGTH) {
				const response = await zipAddressService.get(newPostcode);
				const address = response.data;

				this.provinceSelect = this.findProvinceSelect(address.pref);
				this.model.address = address.fullAddress;
			} else if (newPostcode.length > CommonConst.POST_CODE_LENGTH) {
				this.model.postcode = oldPostcode;
			} else {
				this.provinceSelect = {};
				this.model.address = '';
			}
		},
	},
	methods: {
		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onCopy(copyData, inputIdx) {
			const copyDataFormatted = `${CommonConst.PRE_TEXT_LINE_LINK}${copyData}`;
			let self = this;
			this.$copyText(copyDataFormatted).then(
				function (e) {
					self.textContent = 'コピー済み';
					setTimeout(() => {
						self.textContent = 'URLをコピー';
					}, 1000);
					console.log(e);
				},
				function (e) {
					self.textContent = 'コピー失敗';
					console.log(e);
				}
			);
		},

		findProvinceSelect(provinceName) {
			return this.provinceOptions.find((province) => province.label === provinceName);
		},

		async onEdit() {
			this.notiMode = CommonConst.EDIT_MODE;
			this.notiSingleBtn = false;
			this.notiContent = '変更します。\nよろしいですか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		async editClient() {
			this.model.postcodeAddressJapan = this.convertToHalfWidth(
				this.model.postcodeAddressJapan
			);
			let updatedClientInfo = {
				account_holder: this.model.bankAccountHolder,
				account_number: this.model.bankAccountNumber,
				account_type: this.model.bankAccountType,
				address: this.model.address,
				client_name: this.model.clientName,
				contract_start_date: moment(this.contractDate).format('YYYY/MM/DD'),
				email_address: this.model.mailAddress,
				fax_number: this.model.faxNumber,
				phone_number: this.model.phoneNumber,
				// postal_code: this.model.postcode,
				postal_code: this.model.postcodeAddressJapan,
				province_id: parseInt(this.provinceSelect.value),
				remark: this.model.clientRemark,
				update_user: this.loginUserId,
				responsible_id: this.loginUserId,
				responsible_name: this.model.managerName,
				responsible_name_furigana: this.model.managerNameInFurigana,
			};

			let response = await ClientService.update(this.clientId, updatedClientInfo);
			if (response[0]) {
				this.redirectLink = 'searchclient';
				this.notiSingleBtn = true;
				this.notiContent = '変更しました。';
				this.$bvModal.show(`${this.notiModalId}`);
			}
		},

		async deleteClient() {
			const deleteItem = {
				delete_flag: 1,
			};
			let response = await ClientService.update(this.clientId, deleteItem);
			if (response[0]) {
				this.$router.push('searchclient');
			}
		},

		onDelete() {
			this.notiMode = CommonConst.DELETE_MODE;
			this.notiContent = !this.isClientHaveActiveProject
				? '削除します。\nよろしいですか？'
				: '現在、このクライアントには進行中案件が存在します\n本当に削除しますか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			if (this.notiMode === CommonConst.EDIT_MODE) {
				this.editClient();
				return;
			}
			this.deleteClient();
		},

		async checkEmailValidate(userId, email) {
			let [response, error] = await await UserService.checkExists({
				email_address: email,
			});
			if (error) {
				console.log(error);
			}
			if (response) {
				if (response.id == userId) {
					return true;
				}
				return !response.exists;
			}
		},
	},
};
</script>
